<template>
    <v-card :loading="loading">
        <v-card-title ref="title">
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>{{ editionView ? 'Edit Package' : 'New Package' }}</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text
            id="virtual-scroll-table"
            class="px-4 pb-0"
            :style="`height: ${height}px`"
        >
            <v-form v-model="valid" class="pt-4">
                <v-row>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <v-autocomplete
                            class="pt-0"
                            v-model="packageDimensions.units"
                            :items="measureUnits"
                            hide-details
                            label="Measure Unit"
                            prepend-icon="mdi-ruler"
                            required
                            :rules="[rules.required]"
                            @change="structureData()"
                            :disabled="disableUpdates"
                        />
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <v-text-field
                            class="pt-0"
                            v-model="packageDimensions.width"
                            hide-details
                            type="number"
                            label="Package Width"
                            required
                            prepend-icon="mdi-arrow-left-right"
                            :rules="[rules.number]"
                            @input="structureData()"
                            :disabled="disableUpdates"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <v-text-field
                            class="pt-0"
                            v-model="packageDimensions.height"
                            hide-details
                            type="number"
                            label="Package Height"
                            required
                            prepend-icon="mdi-arrow-up-down"
                            :rules="[rules.number]"
                            @input="structureData()"
                            :disabled="disableUpdates"
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="6">
                        <v-text-field
                            class="pt-0"
                            v-model="packageDimensions.depth"
                            hide-details
                            type="number"
                            label="Package Length"
                            required
                            prepend-icon="mdi-arrow-expand"
                            :rules="[rules.number]"
                            @input="structureData()"
                            :disabled="disableUpdates"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" xl="3" lg="3" md="3" sm="3" class="my-3">
                        <v-autocomplete
                            v-model="packageBOM"
                            :items="BOMs"
                            item-text="code"
                            label="Package BOM"
                            prepend-icon="mdi-package-variant"
                            hide-details
                            @change="structureData()"
                            :disabled="disableUpdates"
                        ></v-autocomplete>
                    </v-col>
                    <v-row cols="12" xl="9" lg="9" md="9" sm="9" class="py-3">
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            class="py-3"
                        >
                            <v-combobox
                                v-model="selectedWorkOrders"
                                :items="filteredWorkOrders"
                                item-text="code"
                                label="WorkOrders"
                                prepend-icon="mdi-hammer-wrench"
                                multiple
                                chips
                                small-chips
                                @change="structureData()"
                                :disabled="disableUpdates"
                            ></v-combobox>
                        </v-col>
                        <v-col
                            cols="12"
                            xl="6"
                            lg="6"
                            md="6"
                            sm="6"
                            class="py-3"
                        >
                            <v-combobox
                                v-model="selectedBOMs"
                                :items="filteredBOMs"
                                item-text="code"
                                label="BOMs"
                                prepend-icon="mdi-list-box-outline"
                                multiple
                                chips
                                small-chips
                                @change="structureData()"
                                :disabled="disableUpdates"
                            ></v-combobox>
                        </v-col>
                    </v-row>
                </v-row>
            </v-form>
            <v-container fluid px-0>
                <v-row no-gutters v-if="selectedWorkOrders.length > 0">
                    <v-col
                        cols="12"
                        v-for="(workOrder, i) in selectedWorkOrders"
                        :key="i"
                        class="mb-5"
                    >
                        <v-data-table
                            :headers="headers"
                            :items="sortedItems(workOrder)"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            multiple-expand
                            :expanded.sync="workOrder.items"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="12" class="d-flex">
                                        <h2 class="mt-1">
                                            WORK ORDER:
                                            {{ workOrder.code }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.partNumber`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.partNumber }}
                                </p>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.description }}
                                </p>
                            </template>
                            <template v-slot:[`item.mass`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.mass }} Kg
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="my-0">
                                    {{ item.quantity || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.packedQty`]="{ item }">
                                <p class="my-0">
                                    {{ item.packedQty || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.availableQty`]="{ item }">
                                <p class="my-0">
                                    {{ item.availableQty || 0 }}
                                </p>
                            </template>
                            <template
                                v-slot:[`item.suggestedAvailableQty`]="{ item }"
                            >
                                <p class="my-0">
                                    {{ item.suggestedAvailableQty }}
                                </p>
                            </template>
                            <template v-slot:[`item.packingQty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        type="number"
                                        v-model="item.packingQty"
                                        style="max-width: 50px"
                                        :rules="[
                                            maxValue(item),
                                            rules.optionalNumber,
                                        ]"
                                        @input="structureData()"
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.suggestedQty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        v-if="!suggestedProcess"
                                        type="number"
                                        v-model="item.suggestedQty"
                                        style="max-width: 50px"
                                        :rules="[
                                            maxSuggestedValue(item),
                                            rules.optionalNumber,
                                        ]"
                                        @input="structureData()"
                                    />
                                    <p v-else class="my-0">
                                        {{ item.suggestedQty }}
                                    </p>
                                </div>
                            </template>
                            <template v-slot:[`item.packingMass`]="{ item }">
                                <p class="my-0">
                                    {{ item.packingQty * item.mass || 0 }} Kg
                                </p>
                            </template>
                            <template
                                v-slot:[`item.suggestedPackingMass`]="{ item }"
                            >
                                <p class="my-0">
                                    {{ item.suggestedQty * item.mass || 0 }} Kg
                                </p>
                            </template>
                            <template v-slot:[`item.notes`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        v-model="item.notes"
                                        @input="structureData()"
                                        :disabled="disableUpdates"
                                    />
                                </div>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    <td v-if="!onlySuggested" />
                                    <td v-if="!onlySuggested" />
                                    <td />
                                    <td
                                        class="font-weight-bold"
                                        style="text-align: center;"
                                        v-if="suggestedProcess || onlySuggested"
                                    >
                                        {{
                                            workOrder.items.reduce(
                                                (accumulator, currentValue) =>
                                                    Number(accumulator) +
                                                    Number(
                                                        currentValue.suggestedQty ||
                                                            0
                                                    ),
                                                0
                                            ) || 0
                                        }}
                                    </td>
                                    <td
                                        class="font-weight-bold"
                                        style="text-align: center;"
                                        v-if="!onlySuggested"
                                    >
                                        {{
                                            workOrder.items.reduce(
                                                (accumulator, currentValue) =>
                                                    Number(accumulator) +
                                                    Number(
                                                        currentValue.packingQty ||
                                                            0
                                                    ),
                                                0
                                            ) || 0
                                        }}
                                    </td>
                                    <td
                                        class="font-weight-bold"
                                        style="text-align: center;"
                                    >
                                        {{
                                            workOrder.items.reduce(
                                                (accumulator, currentValue) =>
                                                    Number(accumulator) +
                                                    onlySuggested
                                                        ? Number(
                                                              currentValue.suggestedQty *
                                                                  currentValue.mass ||
                                                                  0
                                                          )
                                                        : Number(
                                                              currentValue.packingQty *
                                                                  currentValue.mass ||
                                                                  0
                                                          ),
                                                0
                                            ) || 0
                                        }}
                                        Kg
                                    </td>
                                    <td />
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row no-gutters v-if="selectedBOMs.length > 0">
                    <v-col
                        cols="12"
                        v-for="(bom, i) in selectedBOMs"
                        :key="i"
                        class="mb-5"
                    >
                        <v-data-table
                            :headers="bomHeaders"
                            :items="sortedBOMItems(bom)"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                            multiple-expand
                            :expanded.sync="bom.items"
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="12" class="d-flex">
                                        <h2 class="mt-1">
                                            BOM:
                                            {{ bom.code }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.code`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.code }}
                                </p>
                            </template>
                            <template v-slot:[`item.description`]="{ item }">
                                <p
                                    class="my-0 font-weight-bold text-capitalize"
                                >
                                    {{ item.description }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="my-0">
                                    {{ item.quantity || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.packedQty`]="{ item }">
                                <p class="my-0">
                                    {{ item.packedQty || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.availableQty`]="{ item }">
                                <p class="my-0">
                                    {{ item.availableQty || 0 }}
                                </p>
                            </template>
                            <template v-slot:[`item.packingQty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        type="number"
                                        v-model="item.packingQty"
                                        style="max-width: 50px"
                                        :rules="[
                                            maxValue(item),
                                            rules.optionalNumber,
                                        ]"
                                        @input="structureData()"
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.notes`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        v-model="item.notes"
                                        @input="structureData()"
                                    />
                                </div>
                            </template>
                            <template
                                v-slot:[`item.suggestedAvailableQty`]="{ item }"
                            >
                                <p class="my-0">
                                    {{ item.suggestedAvailableQty }}
                                </p>
                            </template>
                            <template v-slot:[`item.suggestedQty`]="{ item }">
                                <div class="d-flex justify-center">
                                    <v-text-field
                                        v-if="!suggestedProcess"
                                        type="number"
                                        v-model="item.suggestedQty"
                                        style="max-width: 50px"
                                        :rules="[
                                            maxSuggestedValue(item),
                                            rules.optionalNumber,
                                        ]"
                                        @input="structureData()"
                                    />
                                    <p v-else class="my-0">
                                        {{ item.suggestedQty }}
                                    </p>
                                </div>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td />
                                    <td />
                                    <td />
                                    <td v-if="!onlySuggested" />
                                    <td v-if="!onlySuggested" />
                                    <td />
                                    <td
                                        class="font-weight-bold"
                                        style="text-align: center;"
                                        v-if="suggestedProcess || onlySuggested"
                                    >
                                        {{
                                            bom.items.reduce(
                                                (accumulator, currentValue) =>
                                                    Number(accumulator) +
                                                    Number(
                                                        currentValue.suggestedQty ||
                                                            0
                                                    ),
                                                0
                                            ) || 0
                                        }}
                                    </td>
                                    <td
                                        class="font-weight-bold"
                                        style="text-align: center;"
                                        v-if="!onlySuggested"
                                    >
                                        {{
                                            bom.items.reduce(
                                                (accumulator, currentValue) =>
                                                    Number(accumulator) +
                                                    Number(
                                                        currentValue.packingQty ||
                                                            0
                                                    ),
                                                0
                                            ) || 0
                                        }}
                                    </td>
                                    <td />
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-col
                    v-if="
                        selectedWorkOrders.length == 0 &&
                            selectedBOMs.length == 0
                    "
                >
                    <v-divider />
                    <h3 class="d-flex align-center justify-center pt-4">
                        No work Orders or BOMs selected
                    </h3>
                </v-col>
            </v-container>
            <v-row
                no-gutters
                style="background-color: #eeeeee"
                class="mt-8"
                ref="totals"
            >
                <v-col cols="12" xl="3" lg="3" md="3">
                    <p class="my-2 font-weight-black ml-4">PACKAGE TOTALS</p>
                </v-col>
                <v-col cols="12" xl="3" lg="3" md="3">
                    <p class="my-2  ml-4 font-weight-black">
                        WORK ORDERS SELECTED:
                        {{ selectedWorkOrders.length }}
                    </p>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2">
                    <p class="my-2  ml-4 font-weight-black">
                        BOMS SELECTED:
                        {{ selectedBOMs.length }}
                    </p>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2">
                    <p class="my-2  ml-4 font-weight-black">
                        PACKAGE ITEMS: {{ packageTotalItems() }}
                    </p>
                </v-col>
                <v-col cols="12" xl="2" lg="2" md="2">
                    <p class="my-2  ml-4 font-weight-black">
                        TOTAL MASS: {{ packageTotalMass() }} Kg
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions ref="actions">
            <v-spacer />
            <v-btn
                color="primary"
                class="mb-4"
                @click="addPackage"
                rounded
                :disabled="disableAction()"
            >
                {{ editionView ? 'SAVE' : 'ADD PACKAGE' }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { isEmpty } from 'lodash'
import _ from 'lodash'
import { objDiff } from '@/helpers/objDiff.js'
import API from '@/services/api'

export default {
    name: 'PackageForm',
    props: {
        projectWorkOrders: {
            type: Array,
            required: true,
            default: () => [],
        },
        BOMs: {
            type: Array,
            required: true,
            default: () => [],
        },
        measureUnits: {
            type: Array,
            required: true,
            default: () => [],
        },
        editionView: {
            type: Boolean,
            required: true,
            default: () => false,
        },
        originalPackage: {
            type: Object,
            required: false,
            default: () => {},
        },
        packingList: {
            type: Object,
            required: false,
            default: () => {},
        },
        onlySuggested: {
            type: Boolean,
            required: true,
            default: () => true,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        selectedBOMs: [],
        selectedWorkOrders: [],
        filteredWorkOrders: [],
        filteredBOMs: [],
        packageDimensions: {
            units: null,
            width: null,
            height: null,
            depth: null,
        },
        packageBOM: null,
        rules: {
            optionalNumber: v =>
                (isNaN(v) && v != '') || parseInt(v) > 0 || 'Invalid number',
            required: v => !!v || 'Required',
            number: v => (!isNaN(v) && parseInt(v) > 0) || 'Invalid number',
        },
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'UNIT MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKED QTY',
                value: 'packedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'availableQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING QTY',
                value: 'packingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING MASS',
                value: 'packingMass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        bomHeaders: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKED QTY',
                value: 'packedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'availableQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING QTY',
                value: 'packingQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        suggestedHeaders: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'UNIT MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'suggestedAvailableQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SUGGESTED QTY',
                value: 'suggestedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PACKING MASS',
                value: 'suggestedPackingMass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        suggestedBomHeaders: [
            {
                text: 'INDEX',
                value: 'index',
                align: 'center',
                sortable: false,
            },
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'AVAILABLE QTY',
                value: 'suggestedAvailableQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'SUGGESTED QTY',
                value: 'suggestedQty',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
        ],
        height: 0,
        structuredData: {},
        WOs: [],
        disableUpdates: false,
        suggestedProcess: false,
    }),
    computed: {
        packageDiff: function() {
            const packageData = this.structuredData
            this.originalPackage.items.forEach(
                item => (item.packingQty = Number(item.packingQty))
            )
            if (this.originalPackage) {
                return objDiff(this.originalPackage, packageData)
            } else {
                return {}
            }
        },
    },
    async mounted() {
        try {
            this.WOs = _.cloneDeep(this.projectWorkOrders)
            this.initWorkOrders()
            this.initBOMs()
            if (this.originalPackage) {
                this.packageDimensions = this.originalPackage.packageDimensions
                this.packageBOM = this.originalPackage.packageBOM
            }
            if (this.packingList.status == 'pending') {
                this.disableUpdates = true
            }
            if (this.onlySuggested) {
                this.headers = this.suggestedHeaders
                this.bomHeaders = this.suggestedBomHeaders
            } else if (this.originalPackage) {
                this.suggestedProcess = this.originalPackage.items.some(
                    item => item.suggestedQty
                )

                if (this.suggestedProcess) {
                    const suggestedHeader = {
                        text: 'SUGGESTED QTY',
                        value: 'suggestedQty',
                        align: 'center',
                        sortable: false,
                    }
                    this.headers.splice(7, 0, suggestedHeader)
                    this.bomHeaders.splice(4, 0, suggestedHeader)
                }
            }

            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                title: { clientHeight: titleHeight },
                actions: { clientHeight: actionsHeight },
            } = this.$refs
            this.height = window.innerHeight - titleHeight - actionsHeight - 10
        },
        initWorkOrders() {
            this.filteredWorkOrders = this.WOs.filter(
                workOrder => workOrder.items
            )
            if (this.originalPackage) {
                const codes = this.originalPackage.items.map(i => i.workOrder)
                this.selectedWorkOrders = this.filteredWorkOrders.filter(wo =>
                    codes.includes(wo.code)
                )

                const items = this.originalPackage.items
                const workOrders = this.selectedWorkOrders.map(w => w.code)

                this.filteredWorkOrders.forEach(wo => {
                    if (workOrders.includes(wo.code)) {
                        const filteredItems = items.filter(
                            item => item.workOrder == wo.code
                        )
                        filteredItems.forEach(item => {
                            const index = wo.items.findIndex(
                                woItem =>
                                    woItem.partNumberId == item.partNumberId
                            )

                            if (index > -1) {
                                const currentItem = wo.items[index]
                                currentItem.notes = item.notes
                                const newAvailableQty =
                                    Number(currentItem.availableQty || 0) -
                                    Number(currentItem.packedQty || 0) +
                                    Number(item.packingQty || 0)

                                const suggestedAvailableQty =
                                    Number(
                                        currentItem.availableSuggested || 0
                                    ) + Number(item.suggestedQty || 0)

                                currentItem.availableQty = newAvailableQty
                                currentItem.suggestedAvailableQty = suggestedAvailableQty
                                currentItem.suggestedQty = Number(
                                    item.suggestedQty
                                )
                                currentItem.packingQty = Number(item.packingQty)
                            }
                        })
                    }
                })
            } else {
                this.filteredWorkOrders.forEach(wo => {
                    wo.items.forEach(item => {
                        const newAvailableQty =
                            Number(item.availableQty) -
                            Number(item.packedQty || 0)

                        item.availableQty = newAvailableQty

                        const suggestedAvailableQty = Number(
                            item.availableSuggested || 0
                        )
                        item.suggestedQty = null
                        item.suggestedAvailableQty = suggestedAvailableQty
                    })
                })
            }
        },
        initBOMs() {
            this.filteredBOMs = this.BOMs.filter(bom => bom.items)
            if (this.originalPackage) {
                const codes = this.originalPackage.items.map(i => i.bom)
                this.selectedBOMs = this.filteredBOMs.filter(bom =>
                    codes.includes(bom.code)
                )

                const items = this.originalPackage.items
                const BOMs = this.selectedBOMs.map(w => w.code)

                this.filteredBOMs.forEach(bom => {
                    if (BOMs.includes(bom.code)) {
                        const filteredItems = items.filter(
                            item => item.bom == bom.code
                        )
                        filteredItems.forEach(item => {
                            const index = bom.items.findIndex(
                                bomItem => bomItem.code == item.code
                            )

                            if (index > -1) {
                                const currentItem = bom.items[index]
                                currentItem.notes = item.notes
                                const newAvailableQty =
                                    Number(currentItem.availableQty || 0) -
                                    Number(currentItem.packedQty || 0) +
                                    Number(item.packingQty || 0)

                                const suggestedAvailableQty =
                                    Number(
                                        currentItem.availableSuggested || 0
                                    ) + Number(item.suggestedQty || 0)

                                currentItem.availableQty = newAvailableQty
                                currentItem.suggestedAvailableQty = suggestedAvailableQty
                                currentItem.suggestedQty = Number(
                                    item.suggestedQty
                                )
                                currentItem.packingQty = Number(item.packingQty)
                            }
                        })
                    }
                })
            } else {
                this.filteredBOMs.forEach(bom => {
                    bom.items.forEach(item => {
                        const newAvailableQty =
                            Number(item.availableQty) -
                            Number(item.packedQty || 0)
                        item.availableQty = newAvailableQty

                        const suggestedAvailableQty = Number(
                            item.availableSuggested || 0
                        )
                        item.suggestedQty = null
                        item.suggestedAvailableQty = suggestedAvailableQty
                    })
                })
            }
        },
        disableAction() {
            let validWO = false
            let validBOM = false
            if (this.onlySuggested) {
                validWO = this.selectedWorkOrders.every(
                    workOrder =>
                        workOrder.items.some(item => item.suggestedQty > 0) &&
                        workOrder.items.every(
                            item =>
                                item.suggestedQty === undefined ||
                                item.suggestedQty === '' ||
                                (item.suggestedQty > 0 &&
                                    Number(item.suggestedQty || 0) <=
                                        item.suggestedAvailableQty)
                        )
                )

                validBOM = this.selectedBOMs.every(
                    bom =>
                        bom.items.some(item => item.suggestedQty > 0) &&
                        bom.items.every(
                            item =>
                                item.suggestedQty === undefined ||
                                item.suggestedQty === '' ||
                                (item.suggestedQty > 0 &&
                                    Number(item.suggestedQty || 0) <=
                                        item.suggestedAvailableQty)
                        )
                )
            } else {
                validWO = this.selectedWorkOrders.every(
                    workOrder =>
                        workOrder.items.some(item => item.packingQty > 0) &&
                        workOrder.items.every(
                            item =>
                                item.packingQty === undefined ||
                                item.packingQty === '' ||
                                (item.packingQty > 0 &&
                                    Number(item.packingQty || 0) <=
                                        item.availableQty)
                        )
                )

                validBOM = this.selectedBOMs.every(
                    bom =>
                        bom.items.some(item => item.packingQty > 0) &&
                        bom.items.every(
                            item =>
                                item.packingQty === undefined ||
                                item.packingQty === '' ||
                                (item.packingQty > 0 &&
                                    Number(item.packingQty || 0) <=
                                        item.availableQty)
                        )
                )
            }

            const validation =
                !this.valid ||
                (this.selectedWorkOrders.length == 0 &&
                    this.selectedBOMs.length == 0) ||
                (this.originalPackage && isEmpty(this.packageDiff))

            return validation || !validWO || !validBOM
        },
        maxValue(item) {
            if (item.availableQty < item.packingQty) {
                return `The value cannot be greater than ${item.availableQty}.`
            } else {
                return true
            }
        },
        maxSuggestedValue(item) {
            if (item.suggestedAvailableQty < item.suggestedQty) {
                return `The value cannot be greater than ${item.suggestedAvailableQty}.`
            } else {
                return true
            }
        },
        packageTotalItems() {
            if (this.onlySuggested) {
                const totalInWO = this.selectedWorkOrders
                    .flatMap(workOrder => workOrder.items)
                    .filter(item => item.suggestedQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator + Number(currentItem.suggestedQty || 0),
                        0
                    )

                const totalInBOM = this.selectedBOMs
                    .flatMap(bom => bom.items)
                    .filter(item => item.suggestedQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator + Number(currentItem.suggestedQty || 0),
                        0
                    )

                return totalInBOM + totalInWO
            } else {
                const totalInWO = this.selectedWorkOrders
                    .flatMap(workOrder => workOrder.items)
                    .filter(item => item.packingQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator + Number(currentItem.packingQty || 0),
                        0
                    )

                const totalInBOM = this.selectedBOMs
                    .flatMap(bom => bom.items)
                    .filter(item => item.packingQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator + Number(currentItem.packingQty || 0),
                        0
                    )

                return totalInBOM + totalInWO
            }
        },
        packageTotalMass() {
            if (this.onlySuggested) {
                return this.selectedWorkOrders
                    .flatMap(workOrder => workOrder.items)
                    .filter(item => item.suggestedQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator +
                            Number(
                                currentItem.suggestedQty * currentItem.mass || 0
                            ),
                        0
                    )
            } else {
                return this.selectedWorkOrders
                    .flatMap(workOrder => workOrder.items)
                    .filter(item => item.packingQty)
                    .reduce(
                        (accumulator, currentItem) =>
                            accumulator +
                            Number(
                                currentItem.packingQty * currentItem.mass || 0
                            ),
                        0
                    )
            }
        },
        async addPackage() {
            try {
                const packageData = this.structureData()
                if (this.editionView) {
                    if (this.packingList?.id) {
                        await this.updatePackage(packageData)
                    } else {
                        this.$emit('replacePackage', {
                            packageData,
                        })
                    }
                } else {
                    if (this.packingList?.id) {
                        await this.createPackage(packageData)
                    } else {
                        this.$emit('addPackage', {
                            newPackage: packageData,
                        })
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async createPackage(packageData) {
            try {
                this.loading = true
                const newPackage = await API.createPackage({
                    projectId: this.packingList.projectId,
                    packingListId: this.packingList.id,
                    packageData,
                })
                this.$emit('addPackage', {
                    newPackage,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async updatePackage(packageData) {
            try {
                this.loading = true
                packageData.id = this.originalPackage.id
                await API.updatePackage({
                    projectId: this.packingList.projectId,
                    packingListId: this.packingList.id,
                    packageData,
                })
                this.$emit('replacePackage', {
                    packageData,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        structureData() {
            let packageWOItems = this.selectedWorkOrders.flatMap(workOrder => {
                return workOrder.items
                    .filter(
                        item =>
                            item.packingQty ||
                            (this.onlySuggested && item.suggestedQty)
                    )
                    .map(item => ({
                        partNumberId: item.partNumberId,
                        quantity: item.quantity,
                        mass: item.mass,
                        partNumber: item.partNumber,
                        packingQty: Number(item.packingQty),
                        packingMass:
                            Number(item.mass) * Number(item.packingQty),
                        description: item.description,
                        notes: item.notes,
                        workOrder: workOrder.code,
                        suggestedQty: item.suggestedQty,
                    }))
            })
            let packageBOMItems = this.selectedBOMs.flatMap(bom => {
                return bom.items
                    .filter(
                        item =>
                            item.packingQty ||
                            (this.onlySuggested && item.suggestedQty)
                    )
                    .map(item => ({
                        code: item.code,
                        quantity: item.quantity,
                        mass: 0,
                        packingQty: Number(item.packingQty),
                        packingMass: 0,
                        description: item.description,
                        notes: item.notes,
                        bom: bom.code,
                        suggestedQty: item.suggestedQty,
                    }))
            })
            const packageData = {
                items: [...packageWOItems, ...packageBOMItems],
                packageDimensions: this.packageDimensions,
                packageBOM: this.packageBOM,
            }

            this.structuredData = packageData
            return packageData
        },
        sortedItems(workOrder) {
            return [...workOrder.items].sort(
                (a, b) => b.availableQty - a.availableQty
            )
        },
        sortedBOMItems(bom) {
            return [...bom.items].sort(
                (a, b) => b.availableQty - a.availableQty
            )
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: scroll;
}
</style>
